<template>
  <div class="cabinet" v-if="user">
    <div class="">
      <ChangeAvatar
        v-if="avatarSelection"
        @close="switchAvatar"
        @changeAvatar="changeAvatar"
      />
      <Modal
        v-if="changeName"
        @close="toggleChangeName(false)"
        :title="'Редактирование ФИО'"
      >
        <div class="modal__item mb-4">
          <div class="modal-item__title">Фамилия</div>
          <div class="modal-item__value">
            <TextField
              :type="'text'"
              v-model="last_name"
              :rules="[Rules.isRequired(name, 'Нужно заполнить фамилию')]"
            ></TextField>
          </div>
        </div>
        <div class="modal__item mb-4">
          <div class="modal-item__title">Имя</div>
          <div class="modal-item__value">
            <TextField
              :type="'text'"
              v-model="name"
              :rules="[Rules.isRequired(name, 'Нужно заполнить имя')]"
            ></TextField>
          </div>
        </div>
        <div class="modal__item">
          <div class="modal-item__title">Отчество</div>
          <div class="modal-item__value">
            <TextField :type="'text'" v-model="middle_name"></TextField>
          </div>
        </div>
        <div class="modal__save button button_pink" @click="changeUserName()">
          Сохранить
        </div>
      </Modal>
      <Modal
        v-if="changeProfession"
        @close="toggleProfession(false)"
        :title="'Профессиональная деятельность'"
      >
        <div class="modal__item mb-4">
          <div class="modal-item__title">Специальность</div>
          <div class="modal-item__value">
            <Select
              :searchable="true"
              :placeholder="'Выбрать специальность из списка'"
              v-model="speciality"
              :items="specialitiesList"
              @optionTriggered="changeSpeciality"
            />
          </div>
        </div>
        <div class="modal__item mb-4">
          <div class="modal-item__title">Отделение</div>
          <div class="modal-item__value">
            <TextField :type="'text'" v-model="department"></TextField>
          </div>
        </div>
        <div class="modal__item mb-4">
          <div class="modal-item__title">Организация</div>
          <div class="modal-item__value">
            <TextField :type="'text'" v-model="institution" />
          </div>
        </div>
        <div class="modal__item">
          <div class="modal-item__title">Адрес организации</div>
          <div class="modal-item__value">
            <TextField
              type="address"
              v-model="address"
              :rules="[
                Rules.isRequired(
                  address,
                  'Выберите адрес учреждения из выпадающего списка'
                ),
              ]"
            />
          </div>
        </div>
        <div
          class="modal__save button button_pink"
          @click="validate(changeProfessionInfo)"
        >
          Сохранить
        </div>
      </Modal>
    </div>

    <div class="cabinet__user-info">
      <div class="cabinet__avatar-container">
        <div class="cabinet__avatar">
          <img
            class="cabinet__avatar-img"
            :src="
              user.photo
                ? $root.host + user.photo
                : user.default_photo || `https://storage.yandexcloud.net/${$root.backetName}/media/content/public/content/cabinet/default-avatar.svg`
            "
          />
        </div>
        <div
          class="cabinet__change-avatar button button_empty-pink button_small"
        >
          <span class="d-none d-md-inline" @click="switchAvatar(true)">
            Изменить фото
          </span>
          <span class="d-md-none" @click="switchAvatar">Изменить</span>
        </div>
      </div>
      <div class="cabinet__user-info-text">
        <div class="cabinet__title">Мой AZ-MOST</div>
        <div class="cabinet__user-name" v-if="user && Object.keys(user).length">
          {{
            `${user.last_name || ""} ${
              user.first_name[0] ? user.first_name[0] + "." : ""
            }${user.middle_name[0] ? user.middle_name[0] + "." : ""}`
          }}
        </div>
        <div class="cabinet__verified" v-if="user.verified">
          <svg
            class="mr-2"
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_3833_115242)">
              <path
                d="M16 8.5C16 4.08172 12.4183 0.5 8 0.5C3.58172 0.5 0 4.08172 0 8.5C0 12.9183 3.58172 16.5 8 16.5C12.4183 16.5 16 12.9183 16 8.5Z"
                fill="#830051"
              />
              <path
                d="M12 6.5L6.8 11.5L4 8.64285"
                stroke="white"
                stroke-width="1.5"
                stroke-linecap="square"
              />
            </g>
            <defs>
              <clipPath id="clip0_3833_115242">
                <rect
                  width="16"
                  height="16"
                  fill="white"
                  transform="translate(0 0.5)"
                />
              </clipPath>
            </defs>
          </svg>
          Ваш профиль верифицирован
        </div>
        <div class="cabinet__warning" v-else>
          <svg
            class="mr-2"
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect y="0.5" width="16" height="16" rx="8" fill="#D0006F" />
            <path
              d="M6.888 11.168V12.5H8.22V11.168H6.888ZM8.124 6.572V3.932H6.984V6.572L7.284 10.304H7.824L8.124 6.572Z"
              fill="white"
            />
          </svg>
          Изменение данных профиля доступно только верифицированным
          пользователям
        </div>
      </div>
    </div>
    <div class="cabinet__user-info-block">
      <div class="cabinet__user-info-title">Персональные данные</div>
      <div class="cabinet__user-info-item">
        <div class="cabinet__item-title">ФИО</div>
        <div class="cabinet__item-value mb-8">
          <div class="cabinet__item-value-text">
            {{ fullName }}
          </div>
          <div
            class="
              cabinet__item-value-change
              button button_empty-pink button_small
            "
            :class="{ button_disabled: !user.verified }"
            @click="toggleChangeName(true)"
          >
            Изменить
          </div>
        </div>
      </div>
      <div class="cabinet__user-info-item">
        <div class="cabinet__item-title">Email</div>
        <div class="cabinet__item-value mb-8">
          <div class="cabinet__item-value-text" v-if="!changeEmailState">
            {{ user.email }}
          </div>
          <div v-else class="cabinet__item-value-change-block">
            <div v-if="!codeSend">
              <div class="d-none d-md-block">
                <TextField
                  :type="'email'"
                  v-model="email"
                  :label="'Введите новый email'"
                  @keyup.native.enter="validate(getCode)"
                  :rules="[
                    Rules.isRequired(email, 'Нужно заполнить Email адрес'),
                    Rules.isCorrectEmail(email, 'Некорректный email'),
                    !emailAlreadyExists || 'Email уже используется',
                  ]"
                />
                <button
                  @click="getCode()"
                  :disabled="!canRequestCode"
                  class="register-step__get-code button button_pink mt-4"
                >
                  Получить код
                </button>
              </div>
            </div>
            <div v-else-if="!codeHelp">
              <div class="d-none d-md-block">
                <div class="header-auth__description">
                  Отправили письмо с кодом на {{ email }}.
                  <br />
                  <span
                    class="header-auth__description_pink"
                    @click="codeSend = false"
                    >Изменить email?</span
                  >
                </div>
                <TextField
                  :type="'tel'"
                  v-model="emailCode"
                  :maskString="'####'"
                  :readonly="codeConfirmed !== false && emailCode.length > 3"
                  @keyup.native.enter="validate()"
                  :rules="[
                    Rules.isRequired(emailCode, 'Неправильный код'),
                    codeConfirmed !== false || 'Неправильный код',
                  ]"
                  :successState="codeConfirmed"
                  class="mb-4"
                >
                  <Preloader
                    class="ml-4"
                    v-if="codeConfirmed === null && emailCode.length > 3"
                  />
                  <GetCode
                    v-if="!codeConfirmed"
                    :refresh="checkEmail"
                    :args="[email]"
                    class="ml-4 ml-xs-0 mt-xs-4"
                  />
                </TextField>
                <div class="header-auth__code-help" @click="codeHelp = true">
                  Не пришел код
                </div>
              </div>
            </div>
            <div v-else>
              <div class="d-none d-md-block">
                <div class="header-auth__help-caption">Что делать</div>
                <ol class="header-auth__help-list">
                  <li class="header-auth__help-list-item">
                    Вы могли ввести несуществующий email или ошибиться в букве.
                    Пожалуйста, проверьте всё ещё раз.
                  </li>
                  <li class="header-auth__help-list-item">
                    Проверьте папку «Спам» — возможно, письмо попало туда.
                  </li>
                </ol>
                <div
                  class="header-auth__back button button_empty-pink"
                  @click="codeHelp = false"
                >
                  <span class="mr-2">
                    <svg
                      width="7"
                      height="12"
                      viewBox="0 0 7 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6 1L1 6L6 11"
                        stroke="currentColor"
                        stroke-linecap="square"
                      />
                    </svg>
                  </span>
                  Назад
                </div>
              </div>
            </div>

            <div class="d-md-none">
              <div
                class="header-auth__back button button_small button_empty-pink"
                @click="changeEmail(!changeEmailState)"
              >
                <span class="mr-2">
                  <svg
                    width="7"
                    height="12"
                    viewBox="0 0 7 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6 1L1 6L6 11"
                      stroke="currentColor"
                      stroke-linecap="square"
                    />
                  </svg>
                </span>
                Назад
              </div>
              <div class="cabinet__item-title">Новый email</div>
              <div v-if="!codeSend">
                <div class="header-auth__description">
                  На указанный адрес придет код подтверждения
                </div>
                <div class="">
                  <TextField
                    :type="'email'"
                    v-model="email"
                    class="mb-4"
                    :label="'Email'"
                    @keyup.native.enter="validate(getCode)"
                    :rules="[
                      Rules.isRequired(email, 'Нужно заполнить Email адрес'),
                      Rules.isCorrectEmail(email, 'Некорректный email'),
                      !emailAlreadyExists || 'Email уже используется',
                    ]"
                  />
                  <Checkbox
                    style="grid-column-start: 1; grid-column-end: 3"
                    v-if="changeEmailState"
                    :label="`Я хочу получать рассылку с информацией и полезными материалами на Email`"
                    :type="'checkbox'"
                    v-model="subscribeEmail"
                  />
                  <button
                    @click="getCode()"
                    :disabled="!canRequestCode"
                    class="register-step__get-code button button_pink mt-4"
                  >
                    Получить код
                  </button>
                </div>
              </div>
              <div v-else-if="!codeHelp">
                <div class="header-auth__description">
                  Отправили письмо с кодом на {{ email }}.
                  <br />
                  <span
                    class="header-auth__description_pink"
                    @click="changeEmail"
                    >Изменить email?</span
                  >
                </div>
                <TextField
                  :type="'tel'"
                  v-model="emailCode"
                  :maskString="'####'"
                  :label="'Код из письма'"
                  :rules="[
                    Rules.isRequired(emailCode, 'Неправильный код'),
                    codeConfirmed !== false || 'Неправильный код',
                  ]"
                  :successState="codeConfirmed"
                  class="mb-4"
                >
                  <Preloader
                    class="mx-auto my-4"
                    v-if="codeConfirmed === null && emailCode.length > 3"
                  />
                  <GetCode
                    v-if="!codeConfirmed"
                    :refresh="checkEmail"
                    :args="[email]"
                    class="ml-4 ml-xs-0 mt-xs-4"
                  />
                </TextField>
                <div class="header-auth__code-help" @click="codeHelp = true">
                  Не пришел код
                </div>
              </div>
              <div v-else>
                <div class="header-auth__help-caption">Что делать</div>
                <ol class="header-auth__help-list">
                  <li class="header-auth__help-list-item">
                    Вы могли ввести несуществующий email или ошибиться в букве.
                    Пожалуйста, проверьте всё ещё раз.
                  </li>
                  <li class="header-auth__help-list-item">
                    Проверьте папку «Спам» — возможно, письмо попало туда.
                  </li>
                </ol>
                <div
                  class="header-auth__back button button_empty-pink"
                  @click="codeHelp = false"
                >
                  <span class="mr-2">
                    <svg
                      width="7"
                      height="12"
                      viewBox="0 0 7 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6 1L1 6L6 11"
                        stroke="currentColor"
                        stroke-linecap="square"
                      />
                    </svg>
                  </span>
                  Назад
                </div>
              </div>
            </div>
          </div>
          <div
            class="
              cabinet__item-value-change
              button button_empty-pink button_small
            "
            :class="{ button_disabled: !user.verified }"
            @click="changeEmail(!changeEmailState)"
            v-if="
              (user && user.email && !user.email.includes('@astrazeneca')) ||
              !user.email
            "
          >
            {{ changeEmailState ? "Отменить" : "Изменить" }}
          </div>
        </div>
        <Checkbox
          style="grid-column-start: 1; grid-column-end: 3"
          v-if="changeEmailState"
          :label="`Я хочу получать рассылку с информацией и полезными материалами на Email`"
          :type="'checkbox'"
          v-model="subscribeEmail"
          class="mb-8 d-none d-md-flex"
        />
      </div>
      <div class="cabinet__user-info-item">
        <div class="cabinet__item-title">Номер телефона</div>
        <div class="cabinet__item-value mb-8">
          <div class="cabinet__item-value-text" v-if="!changePhoneState">
            {{ user.phone || "+7 (---) --- -- --" }}
          </div>

          <div v-else class="cabinet__item-value-change-block">
            <div v-if="!smsSend">
              <div class="d-none d-md-block">
                <TextField
                  :type="'tel'"
                  v-model="phoneNumber"
                  :label="'Введите номер'"
                  :maskString="'+7 (###) ###-##-##'"
                  :placeholder="'+7(---) --- -- --'"
                  @keyup.native.enter="validate(getSmsCode)"
                  :rules="[
                    Rules.isRequired(phoneNumber, 'Некорректный номер'),
                    Rules.isCorrectPhone(phoneNumber, 'Некорректный номер'),
                    !phoneAlreadyExists || 'Номер уже используется',
                  ]"
                />
                <button
                  @click="getSmsCode()"
                  :disabled="!canRequestSms"
                  class="register-step__get-code button button_pink mt-4"
                >
                  Получить код
                </button>
              </div>
            </div>
            <div v-else-if="!codeHelp">
              <div class="d-none d-md-block">
                <div class="header-auth__description">
                  Отправили смс с кодом на номер +7 {{ phoneNumber }}.
                  <br />
                  <span
                    class="header-auth__description_pink"
                    @click="smsSend = false"
                  >
                    Изменить номер?
                  </span>
                </div>
                <TextField
                  :type="'tel'"
                  v-model="smsCode"
                  :maskString="'####'"
                  @keyup.native.enter="validate()"
                  :rules="[
                    Rules.isRequired(smsCode, 'Неправильный код'),
                    smsConfirmed !== false || 'Неправильный код',
                  ]"
                  :successState="smsConfirmed"
                  class="mb-4"
                >
                  <GetCode
                    v-if="!smsConfirmed"
                    :refresh="checkPhoneResend"
                    class="ml-4 ml-xs-0 mt-xs-4"
                  />
                </TextField>
                <div class="header-auth__code-help" @click="codeHelp = true">
                  Не пришел код
                </div>
              </div>
            </div>
            <div v-else>
              <div class="d-none d-md-block">
                <div class="header-auth__help-caption">Что делать</div>
                <ol class="header-auth__help-list">
                  <li class="header-auth__help-list-item">
                    Возможно, вы ввели не тот номер или ошиблись в цифре.
                    Проверьте всё ещё раз.
                  </li>
                  <li class="header-auth__help-list-item">
                    Проблема может быть со стороны вашего оператора сотовой
                    связи. Обратитесь в службу поддержки.
                  </li>
                </ol>
                <div
                  class="header-auth__back button button_empty-pink"
                  @click="codeHelp = false"
                >
                  <span class="mr-2">
                    <svg
                      width="7"
                      height="12"
                      viewBox="0 0 7 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6 1L1 6L6 11"
                        stroke="currentColor"
                        stroke-linecap="square"
                      />
                    </svg>
                  </span>
                  Назад
                </div>
              </div>
            </div>

            <div class="d-md-none">
              <div
                class="header-auth__back button button_small button_empty-pink"
                @click="changePhone(!changePhoneState)"
              >
                <span class="mr-2">
                  <svg
                    width="7"
                    height="12"
                    viewBox="0 0 7 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6 1L1 6L6 11"
                      stroke="currentColor"
                      stroke-linecap="square"
                    />
                  </svg>
                </span>
                Назад
              </div>
              <div class="cabinet__item-title">Новый номер телефона</div>
              <div v-if="!smsSend">
                <div class="header-auth__description">
                  На указанный номер придет код подтверждения
                </div>
                <div class="">
                  <TextField
                    :type="'tel'"
                    v-model="phoneNumber"
                    :label="'Номер'"
                    class="mb-4"
                    :maskString="'+7 (###) ###-##-##'"
                    :placeholder="'+7(---) --- -- --'"
                    :rules="[
                      Rules.isRequired(phoneNumber, 'Некорректный номер'),
                      Rules.isCorrectPhone(phoneNumber, 'Некорректный номер'),
                      !phoneAlreadyExists || 'Номер уже используется',
                    ]"
                  />
                  <Checkbox
                    style="grid-column-start: 1; grid-column-end: 3"
                    v-if="changePhoneState"
                    :label="`Я хочу получать рассылку с информацией и полезными материалами по СМС `"
                    :type="'checkbox'"
                    v-model="subscribePhone"
                  />
                  <button
                    @click="getSmsCode()"
                    :disabled="!canRequestSms"
                    class="register-step__get-code button button_pink mt-4"
                  >
                    Получить код
                  </button>
                </div>
              </div>
              <div v-else-if="!codeHelp">
                <div class="header-auth__description">
                  Отправили письмо с кодом на {{ phoneNumber }}.
                  <br />
                  <span
                    class="header-auth__description_pink"
                    @click="smsSend = false"
                    >Изменить номер?</span
                  >
                </div>
                <TextField
                  :type="'tel'"
                  v-model="smsCode"
                  :label="'Код из смс'"
                  :maskString="'####'"
                  :rules="[
                    Rules.isRequired(smsCode, 'Неправильный код'),
                    smsConfirmed !== false || 'Неправильный код',
                  ]"
                  :successState="smsConfirmed"
                  class="mb-4"
                >
                  <GetCode
                    v-if="!smsConfirmed"
                    :refresh="checkPhoneResend"
                    class="ml-4 ml-xs-0 mt-xs-4"
                  />
                </TextField>

                <div class="header-auth__code-help" @click="codeHelp = true">
                  Не пришел код
                </div>
              </div>
              <div v-else>
                <div class="header-auth__help-caption">Что делать</div>
                <ol class="header-auth__help-list">
                  <li class="header-auth__help-list-item">
                    Возможно, вы ввели не тот номер или ошиблись в цифре.
                    Проверьте всё ещё раз.
                  </li>
                  <li class="header-auth__help-list-item">
                    Проблема может быть со стороны вашего оператора сотовой
                    связи. Обратитесь в службу поддержки.
                  </li>
                </ol>
                <div
                  class="header-auth__back button button_empty-pink"
                  @click="codeHelp = false"
                >
                  <span class="mr-2">
                    <svg
                      width="7"
                      height="12"
                      viewBox="0 0 7 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6 1L1 6L6 11"
                        stroke="currentColor"
                        stroke-linecap="square"
                      />
                    </svg>
                  </span>
                  Назад
                </div>
              </div>
            </div>
          </div>

          <div
            class="
              cabinet__item-value-change
              button button_empty-pink button_small
            "
            :class="{ button_disabled: !user.verified }"
            @click="changePhone(!changePhoneState)"
            v-if="
              (user && user.email && !user.email.includes('@astrazeneca')) ||
              !user.email
            "
          >
            {{
              changePhoneState
                ? "Отменить"
                : user.phone
                ? "Изменить"
                : "Добавить"
            }}
          </div>
        </div>
        <Checkbox
          style="grid-column-start: 1; grid-column-end: 3"
          v-if="changePhoneState"
          :label="`Я хочу получать рассылку с информацией и полезными материалами по СМС`"
          :type="'checkbox'"
          v-model="subscribePhone"
          class="mb-8 d-none d-md-flex"
        />
      </div>
      <div class="cabinet__user-info-title" v-if="!user.verified">
        *Изменение Email и номера телефона доступно только верифицированным
        пользователям
      </div>
    </div>
    <div class="cabinet__user-info-block">
      <div class="d-md-flex d-block mb-8">
        <div class="cabinet__user-info-title professional">
          Профессиональная деятельность
        </div>
        <div
          class="
            cabinet__item-value-change
            professional__button
            button button_empty-pink button_small
            d-inline-flex
          "
          :class="{ button_disabled: !user.verified }"
          @click="toggleProfession(true)"
        >
          Изменить
        </div>
      </div>

      <div class="cabinet__user-info-item">
        <div class="cabinet__item-title">Специальность</div>
        <div class="cabinet__item-value">
          <div class="cabinet__item-value-text">
            {{ user.speciality }}
          </div>
        </div>
      </div>
      <div class="cabinet__user-info-item">
        <div class="cabinet__item-title">Отделение</div>
        <div class="cabinet__item-value">
          <div class="cabinet__item-value-text">
            {{ user.department }}
          </div>
        </div>
      </div>
      <div class="cabinet__user-info-item">
        <div class="cabinet__item-title">Организация</div>
        <div class="cabinet__item-value">
          <div class="cabinet__item-value-text">
            {{ user.organization }}
          </div>
        </div>
      </div>
      <div class="cabinet__user-info-item">
        <div class="cabinet__item-title">Адрес организации</div>
        <div class="cabinet__item-value">
          <div class="cabinet__item-value-text">
            {{ user.organization_address }}
          </div>
        </div>
      </div>
    </div>
    <div
      class="cabinet__user-info-block"
      v-if="user && user.email && !user.email.includes('@astrazeneca')"
    >
      <div class="cabinet__user-info-title">Безопасность</div>
      <div class="cabinet__user-info-item">
        <div class="cabinet__item-title">Пароль</div>
        <div class="cabinet__item-value">
          <div class="cabinet__item-value-text" v-if="!changePassState">
            ••••••••••••••
          </div>

          <div v-else class="cabinet__item-value-change-block">
            <div class="d-none d-md-block">
              <TextField
                :type="showPass ? 'text' : 'password'"
                v-model="password"
                :label="'Новый пароль'"
                :rules="[
                  Rules.isRequired(
                    password,
                    'Пароль не соответствует требованиям'
                  ),
                  Rules.onlyLatin(
                    password,
                    'Пароль не соответствует требованиям'
                  ),
                  Rules.minLength(
                    password,
                    'Пароль не соответствует требованиям'
                  ),
                  Rules.digitsReq(
                    password,
                    'Пароль не соответствует требованиям'
                  ),
                  !inputError || inputError,
                ]"
                :successState="
                  (Rules.isRequired(password, false) &&
                    Rules.onlyLatin(password, false) &&
                    Rules.minLength(password, false) &&
                    Rules.digitsReq(password, false) &&
                    !inputError) ||
                  false
                "
                class="mb-8 pass-block"
              >
                <template v-slot:append-icon>
                  <div
                    class="show-icon"
                    @click="showPass = !showPass"
                    :class="{ 'show-icon_active': showPass }"
                  >
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M14.5 8C14.5 8 11.5899 12.5 8 12.5C4.41015 12.5 1.5 8 1.5 8C1.5 8 4.41015 3.5 8 3.5C11.5899 3.5 14.5 8 14.5 8Z"
                        stroke="currentColor"
                        stroke-linecap="square"
                      />
                      <circle
                        cx="8"
                        cy="8"
                        r="2.5"
                        stroke="currentColor"
                        stroke-linecap="square"
                      />
                    </svg>
                  </div>
                </template>
                <template v-slot:alternate-error>
                  <div class="pass-requirements">
                    <div class="req__title">Требования к паролю</div>
                    <ul class="req__list mt-2">
                      <li
                        class="req__point mb-2"
                        :class="[
                          Rules.onlyLatin(password, false)
                            ? 'success'
                            : 'error',
                        ]"
                      >
                        Только латинские буквы
                      </li>

                      <li
                        class="req__point"
                        :class="[
                          Rules.minLength(password, false)
                            ? 'success'
                            : 'error',
                        ]"
                      >
                        Минимум 8 символов
                      </li>

                      <li
                        class="req__point"
                        :class="[
                          Rules.digitsReq(password, false)
                            ? 'success'
                            : 'error',
                        ]"
                      >
                        Минимум одна цифра
                      </li>
                    </ul>
                  </div>
                </template>
              </TextField>
              <TextField
                :type="showPass ? 'text' : 'password'"
                v-model="passwordRepeat"
                :label="'Повторите пароль'"
                :rules="[
                  Rules.isRequired(passwordRepeat, 'Пароли не совпадают'),
                  passwordRepeat === password || 'Пароли не совпадают',
                ]"
                :successState="passwordRepeat === password"
                class="pass-block"
              >
                <template v-slot:append-icon>
                  <div
                    class="show-icon"
                    @click="showPass = !showPass"
                    :class="{ 'show-icon_active': showPass }"
                  >
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M14.5 8C14.5 8 11.5899 12.5 8 12.5C4.41015 12.5 1.5 8 1.5 8C1.5 8 4.41015 3.5 8 3.5C11.5899 3.5 14.5 8 14.5 8Z"
                        stroke="currentColor"
                        stroke-linecap="square"
                      />
                      <circle
                        cx="8"
                        cy="8"
                        r="2.5"
                        stroke="currentColor"
                        stroke-linecap="square"
                      />
                    </svg>
                  </div>
                </template>
              </TextField>
              <div
                class="change-pass-btn button_pink big-button button"
                @click="validate(changeUserPass, changePassData)"
              >
                Сменить пароль
              </div>
            </div>
            <div class="d-md-none">
              <div
                class="header-auth__back button button_small button_empty-pink"
                @click="changePass(!changePassState)"
              >
                <span class="mr-2">
                  <svg
                    width="7"
                    height="12"
                    viewBox="0 0 7 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6 1L1 6L6 11"
                      stroke="currentColor"
                      stroke-linecap="square"
                    />
                  </svg>
                </span>
                Назад
              </div>
              <div class="cabinet__item-title">Смена пароля</div>
              <TextField
                :type="showPass ? 'text' : 'password'"
                v-model="password"
                :label="'Новый пароль'"
                :rules="[
                  Rules.isRequired(
                    password,
                    'Пароль не соответствует требованиям'
                  ),
                  Rules.onlyLatin(
                    password,
                    'Пароль не соответствует требованиям'
                  ),
                  Rules.minLength(
                    password,
                    'Пароль не соответствует требованиям'
                  ),
                  Rules.digitsReq(
                    password,
                    'Пароль не соответствует требованиям'
                  ),
                  !inputError || inputError,
                ]"
                :successState="
                  (Rules.isRequired(password, false) &&
                    Rules.onlyLatin(password, false) &&
                    Rules.minLength(password, false) &&
                    Rules.digitsReq(password, false) &&
                    !inputError) ||
                  false
                "
                class="mb-8 pass-block"
              >
                <template v-slot:append-icon>
                  <div
                    class="show-icon"
                    @click="showPass = !showPass"
                    :class="{ 'show-icon_active': showPass }"
                  >
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M14.5 8C14.5 8 11.5899 12.5 8 12.5C4.41015 12.5 1.5 8 1.5 8C1.5 8 4.41015 3.5 8 3.5C11.5899 3.5 14.5 8 14.5 8Z"
                        stroke="currentColor"
                        stroke-linecap="square"
                      />
                      <circle
                        cx="8"
                        cy="8"
                        r="2.5"
                        stroke="currentColor"
                        stroke-linecap="square"
                      />
                    </svg>
                  </div>
                </template>
                <template v-slot:alternate-error>
                  <div class="pass-requirements">
                    <div class="req__title">Требования к паролю</div>
                    <ul class="req__list mt-2">
                      <li
                        class="req__point mb-2"
                        :class="[
                          Rules.onlyLatin(password, false)
                            ? 'success'
                            : 'error',
                        ]"
                      >
                        Только латинские буквы
                      </li>

                      <li
                        class="req__point"
                        :class="[
                          Rules.minLength(password, false)
                            ? 'success'
                            : 'error',
                        ]"
                      >
                        Минимум 8 символов
                      </li>

                      <li
                        class="req__point"
                        :class="[
                          Rules.digitsReq(password, false)
                            ? 'success'
                            : 'error',
                        ]"
                      >
                        Минимум одна цифра
                      </li>
                    </ul>
                  </div>
                </template>
              </TextField>
              <TextField
                :type="showPass ? 'text' : 'password'"
                v-model="passwordRepeat"
                :label="'Повторите пароль'"
                :rules="[
                  Rules.isRequired(passwordRepeat, 'Пароли не совпадают'),
                  passwordRepeat === password || 'Пароли не совпадают',
                ]"
                :successState="passwordRepeat === password"
                class="pass-block"
              >
                <template v-slot:append-icon>
                  <div
                    class="show-icon"
                    @click="showPass = !showPass"
                    :class="{ 'show-icon_active': showPass }"
                  >
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M14.5 8C14.5 8 11.5899 12.5 8 12.5C4.41015 12.5 1.5 8 1.5 8C1.5 8 4.41015 3.5 8 3.5C11.5899 3.5 14.5 8 14.5 8Z"
                        stroke="currentColor"
                        stroke-linecap="square"
                      />
                      <circle
                        cx="8"
                        cy="8"
                        r="2.5"
                        stroke="currentColor"
                        stroke-linecap="square"
                      />
                    </svg>
                  </div>
                </template>
              </TextField>
              <div
                class="change-pass-btn button_pink big-button button"
                @click="validate(changeUserPass, changePassData)"
              >
                Сменить пароль
              </div>
            </div>
          </div>
          <div
            class="
              cabinet__item-value-change
              button button_empty-pink button_small
            "
            @click="changePass(!changePassState)"
          >
            {{ changePassState ? "Отменить" : "Изменить" }}
          </div>
        </div>
      </div>
    </div>
    <div
      class="cabinet__user-info-block"
      v-if="user && user.email && !user.email.includes('@astrazeneca')"
    >
      <div class="cabinet__user-info-title">Уведомления</div>
      <div class="cabinet__user-info-item d-block">
        <Checkbox
          :label="`Я хочу получать рассылку с информацией и полезными материалами на Email`"
          :type="'checkbox'"
          v-model="subscribeEmail"
          class="mb-8"
          @change.native="subscribeEmailEvent"
        />
        <Checkbox
          :disabled="!user.phone"
          :label="`Я хочу получать рассылку с информацией и полезными материалами по СМС`"
          :type="'checkbox'"
          v-model="subscribePhone"
          class="mb-xl-8 mb-4"
          @change.native="subscribePhoneEvent"
        />
      </div>
    </div>
    <div
      class="cabinet__logout d-md-inline-flex button button_pink"
      @click="logoutEvent"
    >
      Выйти
      <svg
        class="ml-2"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M12.8711 2.99414H3.98109C2.95627 2.99414 2.12549 3.82492 2.12549 4.84974V19.548C2.12549 20.5728 2.95627 21.4036 3.98109 21.4036H12.8711C13.896 21.4036 14.7267 20.5728 14.7267 19.548V14.7671H13.2267V19.548C13.2267 19.7444 13.0675 19.9036 12.8711 19.9036H3.98109C3.7847 19.9036 3.62549 19.7444 3.62549 19.548V4.84974C3.62549 4.65335 3.7847 4.49414 3.98109 4.49414H12.8711C13.0675 4.49414 13.2267 4.65335 13.2267 4.84974V9.63062H14.7267V4.84974C14.7267 3.82492 13.896 2.99414 12.8711 2.99414Z"
          fill="white"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M19.9 12.1566L10 12.1566L19.9 12.1566Z"
          fill="white"
        />
        <path
          d="M19.9 12.1566L10 12.1566"
          stroke="white"
          stroke-width="1.5"
          stroke-linecap="square"
        />
        <path
          d="M17.8218 9L21 12.1782L17.8218 15.3564"
          stroke="white"
          stroke-width="1.5"
          stroke-linecap="square"
        />
      </svg>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

import TextField from "../components/form-elements/TextField.vue";
import Select from "../components/form-elements/Select.vue";
import Checkbox from "../components/form-elements/Checkbox.vue";
import Breadcrumbs from "../components/pageComponents/Breadcrumbs.vue";
import GetCode from "@/components/form-elements/getCode.vue";
import ChangeAvatar from "@/components/pageComponents/cabinet/ChangeAvatar.vue";
import Modal from "@/components/pageComponents/cabinet/Modal.vue";
import Preloader from "@/components/Preloader.vue";
import { bus } from "@/main";

export default {
  metaInfo: {
    title: "Личный кабинет",
  },
  components: {
    TextField,
    Select,
    Breadcrumbs,
    GetCode,
    ChangeAvatar,
    Checkbox,
    Preloader,
    Modal,
  },
  name: "Cabinet",
  data: () => ({
    subscribeEmail: true,
    subscribePhone: true,
    changeEmailState: false,
    changePhoneState: false,
    changePassState: false,
    avatarSelection: false,
    avatarImg: false,
    codeSend: false,
    smsSend: false,
    codeHelp: false,
    showPass: false,
    emailCode: "",
    smsCode: "",
    phoneNumber: "",
    email: "",
    password: "",
    passwordRepeat: "",
    changeName: false,
    changeProfession: false,
    name: "",
    last_name: "",
    middle_name: "",
    speciality: [],
    institution: "",
    address: "",
    department: "",
    specialties: [
      {
        label: "Администрация",
        value: "Администрация",
      },
      {
        label: "Акушерское дело",
        value: "Акушерское дело",
      },
      {
        label: "Акушерство и гинекология",
        value: "Акушерство и гинекология",
      },
      {
        label: "Аллергология Детская",
        value: "Аллергология Детская",
      },
      {
        label: "Аллергология",
        value: "Аллергология",
      },
      {
        label: "Ангиохирургия",
        value: "Ангиохирургия",
      },
      {
        label: "Андрология",
        value: "Андрология",
      },
      {
        label: "Анестезиология",
        value: "Анестезиология",
      },
      {
        label: "Бактериология",
        value: "Бактериология",
      },
      {
        label: "Биохимия",
        value: "Биохимия",
      },
      {
        label: "Венерология",
        value: "Венерология",
      },
      {
        label: "Вирусология",
        value: "Вирусология",
      },
      {
        label: "Гастроэнтерология",
        value: "Гастроэнтерология",
      },
      {
        label: "Гастроэнтерология Детская",
        value: "Гастроэнтерология Детская",
      },
      {
        label: "Гематология",
        value: "Гематология",
      },
      {
        label: "Генетика",
        value: "Генетика",
      },
      {
        label: "Гепатология",
        value: "Гепатология",
      },
      {
        label: "Гериатрия",
        value: "Гериатрия",
      },
      {
        label: "Гигиена",
        value: "Гигиена",
      },
      {
        label: "Гинекология",
        value: "Гинекология",
      },
      {
        label: "Гинекология Детская",
        value: "Гинекология Детская",
      },
      {
        label: "Гомеопатия",
        value: "Гомеопатия",
      },
      {
        label: "Дерматология",
        value: "Дерматология",
      },
      {
        label: "Дерматология Детская",
        value: "Дерматология Детская",
      },
      {
        label: "Диетология",
        value: "Диетология",
      },
      {
        label: "Иглорефлексотерапия",
        value: "Иглорефлексотерапия",
      },
      {
        label: "Иммунология",
        value: "Иммунология",
      },
      {
        label: "Инфекционные болезни",
        value: "Инфекционные болезни",
      },
      {
        label: "Кардиология",
        value: "Кардиология",
      },
      {
        label: "Кардиореаниматология",
        value: "Кардиореаниматология",
      },
      {
        label: "Клиническая лабораторная диагностика",
        value: "Клиническая лабораторная диагностика",
      },
      {
        label: "Клиническая фармакология",
        value: "Клиническая фармакология",
      },
      {
        label: "Колопроктология",
        value: "Колопроктология",
      },
      {
        label: "Комбустиология",
        value: "Комбустиология",
      },
      {
        label: "Косметология",
        value: "Косметология",
      },
      {
        label: "Логопедия",
        value: "Логопедия",
      },
      {
        label: "Лучевая терапия",
        value: "Лучевая терапия",
      },
      {
        label: "Маммология",
        value: "Маммология",
      },
      {
        label: "Мануальная терапия",
        value: "Мануальная терапия",
      },
      {
        label: "Микология",
        value: "Микология",
      },
      {
        label: "Микробиология",
        value: "Микробиология",
      },
      {
        label: "Микрохирургия",
        value: "Микрохирургия",
      },
      {
        label: "Морфология",
        value: "Морфология",
      },
      {
        label: "Наркология",
        value: "Наркология",
      },
      {
        label: "Неврология",
        value: "Неврология",
      },
      {
        label: "Неврология Детская",
        value: "Неврология Детская",
      },
      {
        label: "Нейрохирургия",
        value: "Нейрохирургия",
      },
      {
        label: "Неонатология",
        value: "Неонатология",
      },
      {
        label: "Нефрология",
        value: "Нефрология",
      },
      {
        label: "Общая врачебная практика (семейная медицина)",
        value: "Общая врачебная практика (семейная медицина)",
      },
      {
        label: "Онкогинекология",
        value: "Онкогинекология",
      },
      {
        label: "Онкология",
        value: "Онкология",
      },
      {
        label: "Онкология Детская",
        value: "Онкология Детская",
      },
      {
        label: "Онкоурология",
        value: "Онкоурология",
      },
      {
        label: "Онкохирургия",
        value: "Онкохирургия",
      },
      {
        label: "Ортодонтия",
        value: "Ортодонтия",
      },
      {
        label: "Ортопедия",
        value: "Ортопедия",
      },
      {
        label: "Отоларингология Детская",
        value: "Отоларингология Детская",
      },
      {
        label: "Оториноларингология",
        value: "Оториноларингология",
      },
      {
        label: "Офтальмология",
        value: "Офтальмология",
      },
      {
        label: "Паразитология",
        value: "Паразитология",
      },
      {
        label: "Пародонтология",
        value: "Пародонтология",
      },
      {
        label: "Патологическая анатомия",
        value: "Патологическая анатомия",
      },
      {
        label: "Педиатрия",
        value: "Педиатрия",
      },
      {
        label: "Пластическая хирургия",
        value: "Пластическая хирургия",
      },
      {
        label: "Профпатология",
        value: "Профпатология",
      },
      {
        label: "Психиатрия",
        value: "Психиатрия",
      },
      {
        label: "Психиатрия Детская и Подростковая",
        value: "Психиатрия Детская и Подростковая",
      },
      {
        label: "Психология",
        value: "Психология",
      },
      {
        label: "Психоневрология",
        value: "Психоневрология",
      },
      {
        label: "Психотерапия",
        value: "Психотерапия",
      },
      {
        label: "Пульмонология",
        value: "Пульмонология",
      },
      {
        label: "Реабилитация",
        value: "Реабилитация",
      },
      {
        label: "Реаниматология",
        value: "Реаниматология",
      },
      {
        label: "Реаниматология Детская",
        value: "Реаниматология Детская",
      },
      {
        label: "Ревматология",
        value: "Ревматология",
      },
      {
        label: "Ревматология Детская",
        value: "Ревматология Детская",
      },
      {
        label: "Рентгенодиагностика",
        value: "Рентгенодиагностика",
      },
      {
        label: "Сексопатология",
        value: "Сексопатология",
      },
      {
        label: "Сердечно-сосудистая хирургия",
        value: "Сердечно-сосудистая хирургия",
      },
      {
        label: "Сестринское дело",
        value: "Сестринское дело",
      },
      {
        label: "Скорая медицинская помощь",
        value: "Скорая медицинская помощь",
      },
      {
        label: "Спортивная медицина",
        value: "Спортивная медицина",
      },
      {
        label: "Стоматологическое Учреждение",
        value: "Стоматологическое Учреждение",
      },
      {
        label: "Стоматология Детская",
        value: "Стоматология Детская",
      },
      {
        label: "Стоматология ортопедическая",
        value: "Стоматология ортопедическая",
      },
      {
        label: "Стоматология терапевтическая",
        value: "Стоматология терапевтическая",
      },
      {
        label: "Стоматология Хирургическая",
        value: "Стоматология Хирургическая",
      },
      {
        label: "Судебно-медицинская экспертиза",
        value: "Судебно-медицинская экспертиза",
      },
      {
        label: "Судебно-психиатрическая экспертиза",
        value: "Судебно-психиатрическая экспертиза",
      },
      {
        label: "Сурдология",
        value: "Сурдология",
      },
      {
        label: "Терапия",
        value: "Терапия",
      },
      {
        label: "Терапия Подростковая",
        value: "Терапия Подростковая",
      },
      {
        label: "Токсикология",
        value: "Токсикология",
      },
      {
        label: "Торакальная хирургия",
        value: "Торакальная хирургия",
      },
      {
        label: "Травматология",
        value: "Травматология",
      },
      {
        label: "Травматология и ортопедия",
        value: "Травматология и ортопедия",
      },
      {
        label: "Трансплантология",
        value: "Трансплантология",
      },
      {
        label: "Трансфузиология",
        value: "Трансфузиология",
      },
      {
        label: "Ультразвуковая диагностика",
        value: "Ультразвуковая диагностика",
      },
      {
        label: "Урология",
        value: "Урология",
      },
      {
        label: "Фармация",
        value: "Фармация",
      },
      {
        label: "Физиология",
        value: "Физиология",
      },
      {
        label: "Физиотерапия",
        value: "Физиотерапия",
      },
      {
        label: "Фтизиатрия",
        value: "Фтизиатрия",
      },
      {
        label: "Функциональная диагностика",
        value: "Функциональная диагностика",
      },
      {
        label: "Химиотерапия",
        value: "Химиотерапия",
      },
      {
        label: "Хирургия",
        value: "Хирургия",
      },
      {
        label: "Хирургия Детская",
        value: "Хирургия Детская",
      },
      {
        label: "Цитология",
        value: "Цитология",
      },
      {
        label: "Челюстно-лицевая хирургия",
        value: "Челюстно-лицевая хирургия",
      },
      {
        label: "Эмбриология",
        value: "Эмбриология",
      },
      {
        label: "Эндокринология",
        value: "Эндокринология",
      },
      {
        label: "Эндокринология Детская",
        value: "Эндокринология Детская",
      },
      {
        label: "Эндоскопия",
        value: "Эндоскопия",
      },
      {
        label: "Эпидемиология",
        value: "Эпидемиология",
      },
      {
        label: "Эпилептология",
        value: "Эпилептология",
      },
      {
        label: "Прочее",
        value: "Прочее",
      },
    ],
  }),
  computed: {
    ...mapGetters([
      "user",
      "Rules",
      "inputError",
      "emailAlreadyExists",
      "phoneAlreadyExists",
      "codeConfirmed",
      "smsConfirmed",
      "specialitiesList",
      "specialityDetail",
    ]),
    fullName() {
      return `${this.user.last_name} ${this.user.first_name} ${this.user.middle_name}`;
    },
    canRequestCode() {
      return this.email && this.Rules.isCorrectEmail(this.email, false);
    },
    canRequestSms() {
      return (
        this.phoneNumber && this.Rules.isCorrectPhone(this.phoneNumber, false)
      );
    },
    changePassData() {
      return {
        id: this.user.id,
        password: this.password,
      };
    },
  },
  methods: {
    ...mapActions([
      "logout",
      "getSession",
      "changeUserInfo",
      "clearInputError",
      "changeUserPass",
      "checkEmail",
      "checkEmailCode",
      "checkPhone",
      "checkPhoneResend",
      "checkPhoneCode",
      "fetchAlerts",
      "fetchSpecialitiesList",
      "fetchSpecialityDetail",
    ]),
    ...mapMutations(["updateCodeConfirmed", "updateSmsConfirmed"]),
    clearAddress() {
      this.address = "";
    },
    async changeSpeciality() {
      await this.fetchSpecialityDetail(this.speciality[0]);
    },
    modalToggle(name, state) {
      this[name] = state;
      bus.$emit("scrollLock", state);
    },
    toggleProfession(state) {
      if (state) {
        if (typeof ym !== "undefined") {
          ym(91468266, "reachGoal", "LK professional activity changing", {
            "LK professional activity changing": {
              action: "Изменить",
              ...this.$root.ymFields,
            },
          });
        }
      } else {
        if (typeof ym !== "undefined") {
          ym(91468266, "reachGoal", "LK professional activity changing", {
            "LK professional activity changing": {
              action: "Закрыть",
              ...this.$root.ymFields,
            },
          });
        }
      }
      this.modalToggle("changeProfession", state);
    },
    toggleChangeName(state) {
      if (state) {
        if (typeof ym !== "undefined") {
          ym(91468266, "reachGoal", "LK name changing", {
            "LK name changing": {
              action: "Изменить",
              ...this.$root.ymFields,
            },
          });
        }
      } else {
        if (typeof ym !== "undefined") {
          ym(91468266, "reachGoal", "LK name changing", {
            "LK name changing": {
              action: "Закрыть",
              ...this.$root.ymFields,
            },
          });
        }
      }
      this.modalToggle("changeName", state);
    },
    changeUserName() {
      if (typeof ym !== "undefined") {
        ym(91468266, "reachGoal", "LK name changing", {
          "LK name changing": {
            action: "Сохранить",
            ...this.$root.ymFields,
          },
        });
      }
      this.modalToggle("changeName", false);

      if (
        this.changeUserInfo({
          id: this.user.id,
          fields: {
            first_name: this.name,
            last_name: this.last_name,
            middle_name: this.middle_name,
          },
        })
      ) {
        this.fetchAlerts({
          alerts: "Сохранено",
          type: "notify",
        });
      }
    },
    changeProfessionInfo() {
      if (typeof ym !== "undefined") {
        ym(91468266, "reachGoal", "LK professional activity changing", {
          "LK professional activity changing": {
            action: "Сохранить",
            ...this.$root.ymFields,
          },
        });
      }
      this.modalToggle("changeProfession", false);
      if (
        this.changeUserInfo({
          id: this.user.id,
          fields: {
            speciality: this.specialitiesList.find(
              (el) => el.value == this.speciality[0]
            ).label,
            organization: this.institution,
            organization_address: this.address,
            department: this.department,
          },
        })
      ) {
        this.fetchAlerts({
          alerts: "Сохранено",
          type: "notify",
        });
      }
    },
    switchAvatar(state) {
      this.avatarSelection = state;
      if (state) {
        if (typeof ym !== "undefined") {
          ym(91468266, "reachGoal", "LK photo change", {
            "LK photo change": {
              ...this.$root.ymFields,
            },
          });
        }
      }
    },
    async changeAvatar(field) {
      this.changeUserInfo({
        id: this.user.id,
        fields: field,
      });
    },
    changeEmailAddress() {
      this.codeSend = false;
      this.updateCodeConfirmed(null);
      this.emailCode = "";
    },
    changePhoneNumber() {
      this.smsSend = false;
      this.updateSmsConfirmed(null);
      this.smsCode = "";
    },
    changeEmail(state) {
      this.changeEmailState = state;
      if (state) {
        this.subscribeEmail = true;
        if (typeof ym !== "undefined") {
          ym(91468266, "reachGoal", "personal data change", {
            "personal data change": {
              "data type": "email",
              ...this.$root.ymFields,
            },
          });
        }
      }
    },
    changePhone(state) {
      this.changePhoneState = state;
      if (state) {
        this.subscribePhone = true;
        if (typeof ym !== "undefined") {
          ym(91468266, "reachGoal", "personal data change", {
            "personal data change": {
              "data type": "phone",
              ...this.$root.ymFields,
            },
          });
        }
      }
    },
    changePass(state) {
      this.changePassState = state;
      if (state) {
        if (typeof ym !== "undefined") {
          ym(91468266, "reachGoal", "personal data change", {
            "personal data change": {
              "data type": "password",
              ...this.$root.ymFields,
            },
          });
        }
      }
    },
    async getCode() {
      this.codeSend = await this.checkEmail({
        email: this.email.toLowerCase(),
        easy: false,
      });
    },
    async getSmsCode() {
      this.smsSend = await this.checkPhone(this.phoneNumber);
    },
    logoutEvent() {
      this.logout();
      if (typeof ym !== "undefined") {
        ym(91468266, "reachGoal", "log out", {
          "log out": {
            ...this.$root.ymFields,
          },
        });
      }
      this.$router.push({ name: "MainPage" });
    },
    async validate(callback, arg) {
      let res = [];
      bus.$emit("validate", this._uid);
      res = bus.data.result;
      if (!res.includes(false) && res.length) {
        if (callback && typeof callback === "function") {
          let result = await callback(arg);
          if (result) {
            this.changePass(false);
            this.changeEmail(false);
            this.changePhone(false);
            bus.data.result = [];
          }
        }
      }
      bus.data.result = [];
    },
    async subscribeEmailEvent() {
      if (
        this.changeUserInfo({
          id: this.user.id,
          fields: {
            agree_email: this.subscribeEmail,
          },
        })
      ) {
        if (this.subscribeEmail) {
          this.fetchAlerts({
            alerts:
              "Вы подписались на рассылку с информацией и полезными материалами на Email",
            type: "notify",
          });
          if (typeof ym !== "undefined") {
            ym(91468266, "reachGoal", "email subscription", {
              "email subscription": {
                checkbox: "on",
                ...this.$root.ymFields,
              },
            });
          }
        } else {
          this.fetchAlerts({
            alerts:
              "Вы отписались от рассылки с информацией и полезными материалами на Email",
            type: "notify",
          });
          if (typeof ym !== "undefined") {
            ym(91468266, "reachGoal", "email subscription", {
              "email subscription": {
                checkbox: "off",
                ...this.$root.ymFields,
              },
            });
          }
        }
      }
    },
    async subscribePhoneEvent() {
      if (
        this.changeUserInfo({
          id: this.user.id,
          fields: {
            agree_sms: this.subscribePhone,
          },
        })
      ) {
        if (this.subscribePhone) {
          this.fetchAlerts({
            alerts:
              "Вы подписались на рассылку с информацией и полезными материалами по СМС",
            type: "notify",
          });
          if (typeof ym !== "undefined") {
            ym(91468266, "reachGoal", "sms subscription", {
              "sms subscription": {
                checkbox: "on",
                ...this.$root.ymFields,
              },
            });
          }
        } else {
          this.fetchAlerts({
            alerts:
              "Вы отписались от рассылки с информацией и полезными материалами по СМС",
            type: "notify",
          });
          if (typeof ym !== "undefined") {
            ym(91468266, "reachGoal", "sms subscription", {
              "sms subscription": {
                checkbox: "off",
                ...this.$root.ymFields,
              },
            });
          }
        }
      }
    },
  },
  async mounted() {
    this.fetchSpecialitiesList()
    if (this.user && Object.keys(this.user).length) {
      this.subscribeEmail = !!this.user.agree_email;
      this.subscribePhone = !!this.user.agree_sms;
      this.name = this.user.first_name;
      this.middle_name = this.user.middle_name;
      this.last_name = this.user.last_name;
      this.speciality = [this.user.speciality];
      this.department = this.user.department;
      this.institution = this.user.organization;
      this.address = this.user.organization_address;
    }
  },
  watch: {
    step() {
      this.showPass = false;
    },
    async emailCode() {
      if (this.emailCode.length >= 4) {
        if (
          await this.checkEmailCode({
            code: this.emailCode,
            subscribe: this.subscribeEmail,
          })
        ) {
          // if (
          //   this.changeUserInfo({
          //     id: this.user.id,
          //     fields: {
          //       email: this.email,
          //       // username: this.email,
          //     },
          //   })
          // ) {
          this.fetchAlerts({
            alerts: "Email успешно изменен",
            type: "notify",
          });
          this.changeEmailState = false;
          this.codeSend = false;
          this.emailCode = "";
          this.email = "";
          this.getSession();
          // }
        }
        this.validate();
      } else {
        this.updateCodeConfirmed(null);
      }
    },
    async smsCode() {
      if (this.smsCode.length >= 4) {
        if (
          await this.checkPhoneCode({
            code: this.smsCode,
            subscribe: this.subscribePhone,
          }).then(() => {
            this.fetchAlerts({
              alerts: "Номер телефона успешно изменен",
              type: "notify",
            });
            this.changePhoneState = false;
            this.getSession();
          })
        ) {
          // if (
          //   this.changeUserInfo({
          //     id: this.user.id,
          //     fields: {
          //       phone: `7${this.phoneNumber}`,
          //     },
          //   })
          // ) {
          //   this.fetchAlerts({
          //     alerts: "Номер телефона успешно изменен",
          //     type: "notify",
          //   });
          //   this.changePhoneState = false;
          // }
        }
        this.validate();
      }
    },
    async emailAlreadyExists() {
      setTimeout(() => {
        this.validate();
      }, 100);
    },
    async phoneAlreadyExists() {
      setTimeout(() => {
        this.validate();
      }, 100);
    },
    async inputError() {
      setTimeout(() => {
        this.validate();
      }, 100);
    },
    async password() {
      if (this.inputError) {
        this.clearInputError();
      }
    },
    async user() {
      this.subscribeEmail = !!this.user.agree_email;
      this.subscribePhone = !!this.user.agree_sms;
      this.name = this.user.first_name;
      this.middle_name = this.user.middle_name;
      this.last_name = this.user.last_name;
      this.speciality = [this.user.speciality];
      this.department = this.user.department;
      this.institution = this.user.organization;
      this.address = this.user.organization_address;
    },
  },
};
</script>

<style lang="scss" scoped>
.cabinet {
  padding-left: 32px;
  padding-top: 48px;
  background-color: #f8f8f8;
  padding-bottom: 80px;
  @media screen and (max-width: 1220px) {
    padding: 40px 0;
  }
  &__user-info {
    padding: 24px 32px;
    background-color: #fff;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 32px;

    @media screen and (max-width: 767px) {
      flex-direction: column;
      text-align: center;
    }
  }

  &__logout {
    width: 176px;
  }

  &__verified {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #3c4242;

    @media screen and (max-width: 767px) {
      font-size: 14px;
    }
  }

  &__title {
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 23px;
    line-height: 29px;
    color: #830051;
    margin-bottom: 8px;
  }

  &__avatar-container {
    margin-right: 24px;
    @media screen and (max-width: 767px) {
      margin-right: 0;
      margin-bottom: 24px;
    }
  }

  &__avatar {
    width: 116px;
    height: 116px;
    border-radius: 50%;
    background-color: #f8f8f8;
    margin: 0 auto;
    overflow: hidden;

    @media screen and (max-width: 767px) {
      width: 85px;
      height: 85px;
    }
  }

  &__avatar-img {
    width: 100%;
    height: 100%;
    object-fit: scale-down;
    object-position: center;
  }

  &__change-avatar {
    width: 137px;
    margin: 8px auto 0;
  }

  &__warning {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #d0006f;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &__user-name {
    margin-bottom: 8px;
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #1f1f1f;
  }

  &__edit-text {
    margin-bottom: 40px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    color: #b2b4b4;

    @media screen and (max-width: 767px) {
      font-size: 12px;
      line-height: 15px;
      margin-bottom: 0;
    }
  }

  &__user-info-block {
    margin-bottom: 40px;

    @media screen and (max-width: 767px) {
      margin-bottom: 32px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__item-value-change-block {
    width: 100%;
    @media screen and (max-width: 1220px) {
      margin-right: 32px;
    }

    @media screen and (max-width: 767px) {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      padding: 36px 16px;
      z-index: 1000;
      background-color: #fff;
      margin-right: 0;
    }
  }

  &__user-info-title {
    margin-bottom: 24px;
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #b2b4b4;

    &.professional {
      margin-bottom: 16px;
    }
  }

  &__user-info-item {
    display: grid;
    grid-template-columns: 1fr 2fr;
    border-bottom: 1px solid #ebefee;
    padding-bottom: 16px;
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }

    @media screen and (max-width: 767px) {
      grid-template-columns: 1fr;
    }
  }

  &__item-value {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    @media screen and (max-width: 767px) {
      margin-top: 12px;
      flex-direction: column;
    }
  }

  &__item-value-change {
    margin-left: auto;
    @media screen and (max-width: 767px) {
      margin-left: 0;
      margin-top: 16px;
      width: 91px;

      &.professional__button {
        margin-top: 0;
        margin-bottom: 24px;
      }
    }
  }

  &__item-title {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 23px;
    color: #3c4242;
  }

  // &__item-title {
  //   margin-top: 32px;
  //   margin-bottom: 16px;
  //   font-family: "Roboto", sans-serif;
  //   font-style: normal;
  //   font-weight: 500;
  //   font-size: 18px;
  //   line-height: 22px;
  //   color: #1f1f1f;
  // }

  &__item-value-text {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #3c4242;
  }

  .header-auth {
    z-index: 1000;
    position: absolute;
    top: 100%;
    right: 0;
    padding: 32px;
    background: #ffffff;
    border-bottom: 4px solid #c4d600;

    &__close {
      position: absolute;
      top: 16px;
      right: 16px;
      cursor: pointer;
    }

    &__tab {
      width: 216px;
    }

    &__get-sms {
      width: 100%;
      height: 40px;
      margin-top: 16px;
    }

    &__title {
      margin-bottom: 16px;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 23px;
      color: #424753;
    }

    &__items {
      margin-bottom: 24px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      border-bottom: 1px solid #d2d2d2;
    }

    &__item {
      width: 100%;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      color: #b2b4b4;
      padding-bottom: 8px;
      cursor: pointer;

      &_active {
        color: #3c4242;
        border-bottom: 2px solid #d0006f;
      }
    }

    &__description {
      margin-bottom: 16px;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      color: #3c4242;

      &_pink {
        text-decoration-line: underline;
        color: #d0006f;
        cursor: pointer;
      }
    }

    &__code-help {
      text-align: left;
      margin-top: 6px;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      text-decoration-line: underline;
      color: #830051;
      cursor: pointer;
      @media screen and (max-width: 767px) {
        text-align: center;
      }
    }

    &__help-caption {
      margin-bottom: 8px;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 27px;
      color: #424753;
    }

    &__help-list {
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      color: #666666;
      padding-left: 15px;
      list-style-type: number;
    }

    &__help-list-item {
      margin-bottom: 4px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &__back {
      margin-bottom: 32px;
      padding: 4px 16px;
      display: inline-flex;
    }
  }
  .register-step__get-code {
    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }
  .show-icon {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    color: #3c4242;
    cursor: pointer;

    &_active {
      color: #d0006f;
    }
  }

  .req__list {
    list-style: disc;
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    width: 350px;
    @media screen and (max-width: 767px) {
      width: 100%;
    }
    .req__point {
      margin-right: 8px;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      color: #b2b4b4;
      list-style-position: inside;
      &:last-child {
        margin-right: 0;
      }
      &.error {
        color: #cb4050;
      }
    }
  }

  .req__title {
    margin: 8px 0;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #3c4242;
  }

  .pass-block {
    .textfield__input-success {
      right: 30px;
    }
  }
  .change-pass-btn {
    margin-top: 16px;
    max-width: 161px;
    @media screen and (max-width: 767px) {
      margin-top: 24px;
      max-width: 100%;
    }
  }
}

.modal {
  &__item {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    @media screen and (max-width: 767px) {
      flex-direction: column;
    }
  }

  &__save {
    width: 117px;
    margin-top: 32px;

    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }

  &-item {
    &__title {
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      color: #3c4242;
      width: 200px;
    }

    &__value {
      width: 280px;
      margin-left: 32px;

      @media screen and (max-width: 767px) {
        margin-left: 0;
      }
    }
  }
}
</style>